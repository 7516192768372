@font-face {
  font-family: iA Writer Quattro V;
  src: url("iAWriterQuattroV-Italic.5f76f118.woff2") format("woff2 supports variations"), url("iAWriterQuattroV-Italic.222f021e.woff") format("woff");
  font-style: italic;
  font-weight: 100 1000;
}

body {
  background: #000;
}

.content {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

span {
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, .55);
  font-family: iA Writer Quattro V, monospace;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
}

.background > img {
  opacity: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.background > img:nth-child(1) {
  animation: 50s ease-in-out -1s infinite fadeinout;
}

.background > img:nth-child(2) {
  animation: 50s ease-in-out 9s infinite fadeinout;
}

.background > img:nth-child(3) {
  animation: 50s ease-in-out 19s infinite fadeinout;
}

.background > img:nth-child(4) {
  animation: 50s ease-in-out 29s infinite fadeinout;
}

.background > img:nth-child(5) {
  animation: 50s ease-in-out 39s infinite fadeinout;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  2%, 20% {
    opacity: 1;
  }

  22%, 100% {
    opacity: 0;
  }
}

/*# sourceMappingURL=index.8986c8ae.css.map */
